import React, { useEffect, useState } from "react";
import { Launcher } from "react-chat-window";
import { sendMessageToTopic, sendMessageToUser } from "./service";
import { onReceiveMessage } from "./firebase-registration";
import userApi from "../reduxToolkit/userApi";

const DEFAULT_TOPIC = "dashboard_notification_chat";

const SMPChat = () => {
  const [messageList, setMessageList] = useState([]);
  const { data: userInfo } = userApi.useGetUserInfoQuery();

  useEffect(() => {
    const _onMessageReceived = (message) => {
      console.log("Received message from : " + message.author);

      if (message.author !== userInfo.name) {
        const senderUserName = message.author;
        message.author = "them";
        if (message.type === "text") {
          message.data.text = `[${senderUserName} => ${message.recipient}]:\n${message.data.text}`;
          setMessageList((ml) => [...ml, message]);
        } else {
          let name_message = {
            author: "them",
            type: "text",
            data: { text: `[${senderUserName} => ${message.recipient}]:` },
          };
          setMessageList((ml) => [...ml, name_message, message]);
        }
      }
    };

    onReceiveMessage((message) => _onMessageReceived(JSON.parse(message)));
  }, [userInfo?.name]);

  const _onMessageWasSent = (message) => {
    message.author = userInfo.name;
    if (message.data.text && message.data.text.startsWith("To:")) {
      const messageTokens = message.data.text.split(":");
      message.data.text = messageTokens[2];
      message.recipient = messageTokens[1].split("@")[0];
      sendMessageToUser(messageTokens[1], JSON.stringify(message));
    } else if (message.data.text && message.data.text.startsWith("Topic:")) {
      const messageTokens = message.data.text.split(":");
      message.data.text = messageTokens[2];
      message.recipient = messageTokens[1];
      sendMessageToTopic(messageTokens[1], JSON.stringify(message));
    } else {
      message.recipient = DEFAULT_TOPIC;
      sendMessageToTopic(DEFAULT_TOPIC, JSON.stringify(message));
    }
    setMessageListOnSent(message);
  };

  const setMessageListOnSent = (message) => {
    message.author = "me";
    if (message.type === "text") {
      message.data.text = `[=> ${message.recipient}]:\n${message.data.text}`;
      setMessageList([...messageList, message]);
    } else {
      const name_message = {
        author: "me",
        type: "text",
        data: { text: `[=> ${message.recipient}]:` },
      };
      setMessageList([...messageList, name_message, message]);
    }
  };

  const agentProfile = {
    teamName: "BCG (" + userInfo.name + ")",
    imageUrl: "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
  };

  return (
    <div className="app">
      <Launcher
        agentProfile={agentProfile}
        onMessageWasSent={_onMessageWasSent}
        messageList={messageList}
        showEmoji
      />
    </div>
  );
};

export default SMPChat;
