import React, { Component } from "react";
import PropTypes from "prop-types";
import { pulseSpinner } from "./Spinner";
import Trigger from "./Trigger";

const defaultErrorMessage = "Error in service call";

class DataLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceStatus: "LOADING",
      data: null,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.callService(this.props.service, this.props.serviceParams);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.reload && nextProps.reload.shouldTrigger(this.props.reload)) {
      this.callService(nextProps.service, nextProps.serviceParams);
    }
  }

  callService = (service, serviceParams) => {
    service(serviceParams)
      .then((response) => {
        this.setState({ serviceStatus: "SUCCESS", data: response });
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.status === 500
            ? defaultErrorMessage
            : error?.response?.data?.description;
        this.setState({ serviceStatus: "ERROR", errorMessage });
      });
  };

  render() {
    let componentToRender;
    switch (this.state.serviceStatus) {
      case "SUCCESS":
        componentToRender = this.props.children(this.state.data);
        break;
      case "ERROR":
        componentToRender = (
          <div>{this.state.errorMessage || defaultErrorMessage}</div>
        );
        break;
      default:
        componentToRender = pulseSpinner();
        break;
    }
    return componentToRender;
  }
}

DataLoader.propTypes = {
  children: PropTypes.func.isRequired,
  service: PropTypes.func.isRequired,
  serviceParams: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  reload: PropTypes.instanceOf(Trigger),
};

DataLoader.defaultProps = {
  reload: null,
};

export default DataLoader;
