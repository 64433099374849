// non-secured apis
export const loginEndpoint = "/authentication/login";
export const pollMFAEndpoint = "/authentication/pollmfa";
export const logoutEndpoint = "/authentication/logout";
export const platformDetailsEndpoint = "/static/platform-details";
export const togglesEndpoint = "/toggles";

// secured apis
export const xApiKeyConfigEndpoint = "/api/configuration/xapikey";
export const resourcesEndpoint = "/api/static/resources";
export const securePlatformDetailsEndpoint = "/api/static/platform-details";
export const statusEndpoint = "/api/static/status";
export const servicesToConfigureEndPoint = (xApiKey) =>
  `/api/configuration/api-gateway/${xApiKey}/services-to-configure`;
export const getOktaUrlsEndpoint = "/api/configurations/okta-urls";
export const getKongInstancesEndpoint = "/api/configurations/kong-instances";
export const getServicesForKongInstancesEndpoint =
  "/api/configuration/api-gateway/services-for-acls";

export const configurationsEndpoint = "/api/configurations";

export const registerTokenEndpoint = "/api/messaging/registerToken";
export const topicMessageEndpoint = (topicName) =>
  `/api/messaging/send/topic/${topicName}`;
export const userMessageEndpoint = (userEmailId) =>
  `/api/messaging/send/user/${userEmailId}`;
export const userDetailsEndpoint = "/api/messaging/userDetails";

export const createStorageContainerEndpoint = "/api/storage/container";
export const getAllStorageContainersEndpoint = "/api/storage/containers";
export const updateStorageContainerAccessEndpoint =
  "/api/storage/container/authorisationConfig";
export const getStorageContainerAccessEndpoint = (
  containerName,
  applicationId
) =>
  `/api/storage/containers/${encodeURIComponent(
    containerName
  )}/applications/${encodeURIComponent(applicationId)}`;
export const securityMatrixGetPath = "/securitymatrix/getAll";

// SPA paths
export const rootPath = "/*";
export const homePath = "/home";
export const alertsPath = "/alerts";
export const statusPath = "/status";
export const resourcesPath = "/resources";
export const addXApiKeyConfigPath = "/configuration/add-xapikey-configuration";
export const editXApiKeyConfigPath =
  "/configuration/edit-xapikey-configuration";
export const viewXApiKeyConfigPath =
  "/configuration/view-xapikey-configuration";
export const listXApiKeyConfigsPath = "/configuration/xapikey-configurations";
export const platformConfigsPath = "/configuration/platform-configurations";
export const addStorageContainerPath = "/configuration/add-storage-container";
export const editStorageContainerAccessPath =
  "/configuration/edit-storage-container-access";
export const addStorageContainerAccessPath =
  "/configuration/add-storage-container-access";
export const listStorageContainersPath = "/configuration/storage-containers";
export const helpPath = "/help";
export const loginPath = "/login";
export const securityMatrixPath = "/security-matrix";
