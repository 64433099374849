import React, { Component } from "react";
import { Provider } from "react-redux";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Login from "./views/Login/Login";
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout";
import { loginPath, rootPath } from "./urls";
import store from "./reduxToolkit/store";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routes>
          <Route path={loginPath} element={<Login />} />
          <Route path={rootPath} element={<DefaultLayout />} />
        </Routes>
      </Provider>
    );
  }
}

export default App;
