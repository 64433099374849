import "firebase/messaging";
import * as firebase from "firebase/app";
import { registerToken } from "./service";

export const registerToFireBase = (userName) => {
  /**
   * To obtain this configuration, follow these steps:
   * 1. Visit https://console.firebase.google.com/ to access the Firebase console.
   * 2. Log in to your Firebase console account.
   * 3. Select your desired project and app.
   * 4. Navigate to the "Project settings" page within the console.
   * 5. In the "Project settings" page, you will find the configuration details.
   */
  const firebaseConfig = {
    apiKey: "AIzaSyBx25u8fyE3Pz7yp1Nc0JqzvHbSxabqkNw",
    authDomain: "platform-messaging.firebaseapp.com",
    databaseURL: "https://platform-messaging.firebaseio.com",
    projectId: "platform-messaging",
    storageBucket: "",
    messagingSenderId: "273792385769",
    appId: "1:273792385769:web:821242a714f11f00106678",
  };

  firebase.initializeApp(firebaseConfig);

  const messaging = firebase.messaging();

  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      messaging
        .getToken({
          /**
           * To obtain the vapidKey, follow these steps:
           * 1. Go to the "Project settings" page of your Firebase project.
           * 2. Navigate to the "Cloud Messaging" tab.
           * 3. Look for the "Web configuration" section within the tab.
           * 4. In the "Web configuration" section, you will find the vapidKey in `Web Push certificates`.
           */
          vapidKey:
            "BDdZVr17-0_R3e3y0nOJlBEZ3RkCtl2M8GQQfk5WAaj2HVJWgGZfw-AaAj3o-NtgTbzU_2rzFgFTNO_LtGl-PqY",
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Received token");
            registerToken(userName, currentToken);
          } else {
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("Unable to get permission to notify.");
    }
  });
};

export const onReceiveMessage = (callback) => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    let messageData = JSON.parse(event.data);
    callback(messageData["notification"]["body"]);
  });
};
