import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import userApi from "./userApi";
import servicesToConfigureApi from "./servicesToConfigureApi";
import { loginPath } from "../urls";

const apiMiddleware = (_) => (next) => async (action) => {
  if (action && action.error) {
    const response = action.meta?.baseQueryMeta?.response;
    if (
      response &&
      response.status === 401 &&
      response.headers.get("redirect_to_login") === "true"
    ) {
      const currentRoute = window.location.hash.replace("#", "");
      if (!currentRoute.startsWith(loginPath)) {
        window.location.href = `/#${loginPath}?redirectTo=${currentRoute}`;
      }
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [servicesToConfigureApi.reducerPath]: servicesToConfigureApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      servicesToConfigureApi.middleware,
      apiMiddleware
    ),
});

setupListeners(store.dispatch);

export default store;
