import axios from "axios";
import { loginPath } from "./urls";

const customAxios = axios.create();

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      error?.response?.headers?.redirect_to_login === "true"
    ) {
      let currentRoute = window.location.hash.replace("#", "");
      if (!currentRoute.startsWith(loginPath)) {
        window.location.href = `/#${loginPath}?redirectTo=${currentRoute}`;
      }
    }
    return Promise.reject(error);
  }
);

const httpOptions = {
  headers: {
    "Content-Type": "application/json",
  },
};

export { customAxios, httpOptions };
