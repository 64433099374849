import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Navigate } from "react-router-dom";
import {
  platformAuthLogin,
  platformAuthPollMFA,
  getPlatformDetails,
} from "./service";
import { toastError, toastWarning } from "../../common/toaster";
import DataLoader from "../../common/DataLoader";
import { homePath } from "../../urls";
import SMPModal from "../../common/SMPModal";
import RequestAccessEmail from "./RequestAccessEmail";
import userApi from "../../reduxToolkit/userApi";
import servicesToConfigureApi from "../../reduxToolkit/servicesToConfigureApi";

const securityWarning =
  "By using the website, you are agreeing to access the BCG resources. Any change or update to the resources will also be subject to the terms of service.";
const bcgLogo = "assets/img/brand/220px-BCG_Corporate_Logo.jpg";

const Login = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showSecurityWarning, setShowSecurityWarning] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    dispatch(userApi.util.resetApiState());
    dispatch(servicesToConfigureApi.util.resetApiState());
  }, [dispatch]);

  const loginSuccess = () => {
    setShowSecurityWarning(false);
    setLoggedIn(true);
  };

  const loginError = (error) => {
    setShowSecurityWarning(false);
    const errorDescription = error.description || "Unexpected error occurred";
    toastError(`Login error. ${errorDescription}`);
  };

  const login = async () => {
    try {
      const response = await platformAuthLogin(userName, password);
      if ("SUCCESS" === response.data["status"]) {
        loginSuccess();
      } else if ("WAITING" === response.data["status"]) {
        toastWarning("Waiting for your Okta Verify input for MFA login.");
        try {
          await platformAuthPollMFA(response.data["pollUri"]);
          loginSuccess();
        } catch (error) {
          let errorResponse = (error.response && error.response.data) || {
            errorCode: "ERROR",
            description: "Unexpected error occurred",
          };
          loginError(errorResponse);
        }
      }
    } catch (error) {
      loginError(error.response.data);
    }
  };

  const redirectTo = () => {
    let redirectParamResults = new RegExp("[?&]redirectTo=([^&#]*)").exec(
      window.location.href
    );
    return redirectParamResults ? redirectParamResults[1] : homePath;
  };

  if (loggedIn) {
    return <Navigate to={redirectTo()} />;
  }

  return (
    <DataLoader service={getPlatformDetails}>
      {(props) => (
        <div className="app flex-row align-items-center">
          <ToastContainer theme="colored" />
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody className="text-center">
                      <Form>
                        <img src={bcgLogo} width="75" height="75" alt="BCG" />
                        <p
                          id="oktaAccount"
                          className="text-muted mt-4"
                        >{`Sign In to ${props.data.oktaAccount}`}</p>
                        <InputGroup className="mb-3">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                          <Input
                            data-testid="username"
                            type="text"
                            placeholder="Username"
                            id="userName"
                            name="userName"
                            onChange={(event) =>
                              setUserName(event.target.value)
                            }
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                          <Input
                            data-testid="password"
                            type="password"
                            placeholder="Password"
                            id="password"
                            name="password"
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </InputGroup>
                        <Row>
                          <Col>
                            <Button
                              data-testid="login-button"
                              color="success"
                              id="loginButton"
                              className="px-4 bg-bcg-green w-100 mt-1"
                              onClick={() => setShowSecurityWarning(true)}
                              autoFocus={true}
                            >
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                    <div className="mt-3">
                      <div className="float-right">
                        <Badge color="light" className="mr-1">
                          {props.data.version}
                        </Badge>
                        <Badge color="dark">{props.data.envName}</Badge>
                      </div>
                    </div>
                  </Card>
                  <Card className="text-white bg-bcg-green py-5 p-4">
                    <CardBody className="text-center">
                      <div>
                        <h2>Request Access</h2>
                        <p>
                          Welcome to the SMP self-service portal, your one-stop
                          platform management dashboard.
                        </p>
                        <RequestAccessEmail
                          adminEmail={props.data.adminEmail}
                          link={props.data.link}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
                <SMPModal
                  isOpen={showSecurityWarning}
                  text={securityWarning}
                  onAccept={login}
                  onCancel={() => {
                    setShowSecurityWarning(false);
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </DataLoader>
  );
};

export default Login;
