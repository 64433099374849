import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { pulseSpinner } from "./Spinner";

const bcgLogo = "assets/img/brand/220px-BCG_Corporate_Logo.jpg";

const SMPModal = (props) => {
  const {
    isOpen,
    text,
    dropCancelButton,
    allowToClose,
    agreeButtonText = "Agree",
    cancelButtonText = "Cancel",
    title = "",
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
      setAgreedTerms(false);
    }
  }, [isOpen]);

  const onAccept = () => {
    setIsLoading(true);
    setAgreedTerms(true);
    props.onAccept();
  };

  const onCancel = () => {
    props.onCancel && props.onCancel();
  };

  const onToggle = () => {
    props.onClose && props.onClose();
  };

  return (
    <Modal
      data-testid="smp-modal"
      isOpen={isOpen}
      className={`${
        allowToClose ? "modal-success" : "modal-warning"
      } modal-dialog-centered`}
      autoFocus={false}
    >
      {allowToClose && (
        <ModalHeader className="bg-bcg-green" toggle={onToggle}>
          {title}
        </ModalHeader>
      )}

      <ModalBody>
        <img
          src={bcgLogo}
          width="75"
          height="75"
          className="mt-5 ml-auto mr-auto d-block"
          alt="BCG"
        />
        <p className="mt-5 ml-5 mr-5">{text}</p>
        {isLoading && pulseSpinner()}
      </ModalBody>
      {!agreedTerms && (
        <ModalFooter>
          <Button
            data-testid="agree-button"
            color="warning"
            id="agreeButton"
            onClick={() => onAccept()}
            autoFocus={true}
          >
            {agreeButtonText}
          </Button>
          {!dropCancelButton && (
            <Button
              data-testid="cancel-button"
              color="secondary"
              id="cancelButton"
              onClick={() => onCancel()}
            >
              {cancelButtonText}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

SMPModal.propTypes = {
  allowToClose: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default SMPModal;
