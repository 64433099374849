import {customAxios, httpOptions} from "../axiosConfiguration";
import {
    togglesEndpoint, registerTokenEndpoint, topicMessageEndpoint, userDetailsEndpoint,
    userMessageEndpoint
} from "../urls";

export const getToggles = () => {
    return customAxios.get(togglesEndpoint, httpOptions)
}

export const registerToken = (userName, deviceToken) => {
    return customAxios.post(registerTokenEndpoint, {
           'userId': userName,
           'token': deviceToken
       }, httpOptions)
}

export const sendMessageToTopic = (topicName, message) => {
    return customAxios.post(topicMessageEndpoint(topicName), {
           'message': message
       }, httpOptions)
}

export const sendMessageToUser = (userEmailId, message) => {
    return customAxios.post(userMessageEndpoint(userEmailId), {
        'message': message
    }, httpOptions)
}

export const getUserDetails = () => {
    return customAxios.get(userDetailsEndpoint, httpOptions)
}
