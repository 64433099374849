import React from "react";
import {
  addXApiKeyConfigPath,
  alertsPath,
  editXApiKeyConfigPath,
  helpPath,
  homePath,
  listXApiKeyConfigsPath,
  addStorageContainerPath,
  listStorageContainersPath,
  resourcesPath,
  statusPath,
  editStorageContainerAccessPath,
  addStorageContainerAccessPath,
  securityMatrixPath,
  viewXApiKeyConfigPath,
  platformConfigsPath
} from "./urls";

const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const Resources = React.lazy(() => import("./views/Resources/Resources"));
const Alerts = React.lazy(() => import("./views/Alerts/Alerts"));
const Status = React.lazy(() => import("./views/Status/Status"));
const Help = React.lazy(() => import("./views/Help/Help"));
const SecurityMatrix = React.lazy(() =>
  import("./views/SecurityMatrix/SecurityMatrix")
);
const AddXApiKeyConfiguration = React.lazy(() =>
  import("./views/Configuration/Application/Components/AddXApiKeyConfiguration")
);
const EditXApiKeyConfiguration = React.lazy(() =>
  import(
    "./views/Configuration/Application/Components/EditXApiKeyConfiguration"
  )
);
const ViewXApiKeyConfiguration = React.lazy(() =>
  import(
    "./views/Configuration/Application/Components/ViewXApiKeyConfiguration"
  )
);
const ViewXApiKeyConfigurations = React.lazy(() =>
  import(
    "./views/Configuration/Application/Components/ViewXApiKeyConfigurations"
  )
);

const PlatformConfigurations = React.lazy(() =>
  import("./views/Configuration/Application/Components/PlatformConfigurations")
);

const AddStorageContainerConfiguration = React.lazy(() =>
  import("./views/Configuration/Storage/AddStorageContainerConfiguration")
);
const ViewStorageContainers = React.lazy(() =>
  import("./views/Configuration/Storage/ViewStorageContainers")
);
const UpdateContainerAccessConfiguration = React.lazy(() =>
  import("./views/Configuration/Storage/UpdateContainerAccessConfiguration")
);
const AddContainerAccessConfiguration = React.lazy(() =>
  import("./views/Configuration/Storage/AddContainerAccessConfiguration")
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: homePath, name: "Home", component: Dashboard },
  { path: alertsPath, name: "Alerts", component: Alerts },
  { path: statusPath, name: "Status", component: Status },
  { path: resourcesPath, name: "Resources", component: Resources },
  {
    path: addXApiKeyConfigPath,
    name: "Add X-Api-Key Configuration",
    component: AddXApiKeyConfiguration,
  },
  {
    path: `${editXApiKeyConfigPath}/:xApiKey`,
    name: "Edit X-Api-Key Configuration",
    component: EditXApiKeyConfiguration,
  },
  {
    path: `${viewXApiKeyConfigPath}/:xApiKey`,
    name: "View X-Api-Key Configuration",
    component: ViewXApiKeyConfiguration,
  },
  {
    path: listXApiKeyConfigsPath,
    name: "All X-Api-Key configurations",
    component: ViewXApiKeyConfigurations,
  },
  {
    path: platformConfigsPath,
    name: "Platform Configurations",
    component: PlatformConfigurations,
  },
  {
    path: listStorageContainersPath,
    name: "Storage Containers",
    component: ViewStorageContainers,
  },
  {
    path: addStorageContainerPath,
    name: "Create Storage Container",
    component: AddStorageContainerConfiguration,
  },
  {
    path: `${editStorageContainerAccessPath}/container/:containerName/application/:applicationId`,
    name: "Edit Storage Container Access",
    component: UpdateContainerAccessConfiguration,
  },
  {
    path: addStorageContainerAccessPath,
    name: "Configure Storage Container Access",
    component: AddContainerAccessConfiguration,
  },
  { path: helpPath, name: "Help", component: Help },
  {
    path: securityMatrixPath,
    name: "SecurityMatrix",
    component: SecurityMatrix,
  },
];

export default routes;
