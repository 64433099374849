import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import { navigation } from "../../_nav";
import routes from "../../routes";
import { ToastContainer } from "react-toastify";
import { homePath, loginPath, rootPath } from "../../urls";
import { pulseSpinner } from "../../common/Spinner";
import { logout, getSecurePlatformDetails } from "./service";
import { toastError } from "../../common/toaster";
import DataLoader from "../../common/DataLoader";
import SMPChat from "../../common/SMPChat";
import Breadcrumb from "../../common/Breadcrumb";
import { getToggles } from "../../common/service";
import { registerToFireBase } from "../../common/firebase-registration";
import userApi from "../../reduxToolkit/userApi";

const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

const DefaultLayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [backToLoginPage, setBackToLoginPage] = useState(false);
  const [enabledSMPChat, setEnabledSMPChat] = useState(false);
  const { data: userInfo } = userApi.useGetUserInfoQuery();

  // This useEffect is used to check and enbled SMP Chat widget based on chatWidget value
  useEffect(() => {
    if (userInfo) {
      getToggles()
        .then((res) => {
          if (res?.data?.chatWidget) {
            setEnabledSMPChat(true);
            registerToFireBase(userInfo.email);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [userInfo]);

  const signOut = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await logout();
      setBackToLoginPage(true);
    } catch (error) {
      toastError("Unable to sign out. Please try again later");
    } finally {
      setIsLoading(false);
    }
  };

  const mainSection = () => {
    return (
      <div>
        <ToastContainer theme="colored" />
        <Breadcrumb />
        <Container fluid>
          <Suspense fallback={pulseSpinner()}>
            <Routes>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.component />}
                  />
                ) : null;
              })}
              <Route path={rootPath} element={<Navigate to={homePath} />} />
            </Routes>
          </Suspense>
        </Container>
      </div>
    );
  };

  if (backToLoginPage) {
    return <Navigate to={loginPath} />;
  }

  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={pulseSpinner()}>
          <DefaultHeader onLogout={(event) => signOut(event)} />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <DataLoader service={getSecurePlatformDetails}>
              {(props) => (
                <AppSidebarNav
                  navConfig={navigation(props.data, userInfo?.role)}
                  {...props}
                  location={window.location}
                />
              )}
            </DataLoader>
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>

        <main className="main">
          {isLoading ? pulseSpinner() : mainSection()}
        </main>

        <AppAside fixed>
          <Suspense fallback={pulseSpinner()}></Suspense>
        </AppAside>
      </div>
      <AppFooter>
        <Suspense fallback={pulseSpinner()}>
          <DefaultFooter />
        </Suspense>
      </AppFooter>
      {enabledSMPChat && <SMPChat />}
    </div>
  );
};

export default DefaultLayout;
