/**
 * Coreui-react library doesn't support inbuit Breadcrumb component with React 18.
 * We created custom Breadcrumb component similar to Coreui-react Breadcrumb component
 */
import React, { useEffect, useState } from "react";
import routes from "../routes";
import withRouter from "./ComponentWithRouterProp";

const Breadcrumb = (props) => {
  const [breadcrumbName, setBreadcrumbName] = useState("");

  useEffect(() => {
    if (props.router?.location) {
      const route = routes.find((route) => {
        // Check for dynamic params path
        if (route.path.includes("/:")) {
          const params = route.path.split("/:");
          return props.router.location.pathname.startsWith(params[0]);
        }

        // Check for default path
        return route.path === props.router.location.pathname;
      });
      setBreadcrumbName(route?.name || "");
    }
  }, [props.router]);

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li
            className="active breadcrumb-item"
            aria-current="page"
            data-testid="breadcrumb-name"
          >
            {breadcrumbName}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default withRouter(Breadcrumb);
