export const deepTrimObjectValues = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    let value = obj[key],
      type = typeof value;
    if (["mode", "readOnly", "getFieldOrder"].includes(key)) return acc;
    if (value !== null) {
      if (Array.isArray(value)) {
        if (value.every((val) => typeof val === "string")) {
          acc[key] = value.map((strVal) => strVal.trim());
        } else if (value.every((val) => typeof val === "object")) {
          acc[key] = value.map((objVal) => deepTrimObjectValues(objVal));
        }
      } else if (type === "string") acc[key] = obj[key].trim();
      else if (type === "object") acc[key] = deepTrimObjectValues(value);
      else acc[key] = value;
    }
    return acc;
  }, {});
};

export const Role = {
  ADMIN: "ADMIN",
  USER: "USER",
};

export const Permissions = {
  VIEW: "VIEW",
  EDIT: "EDIT",
};
