import {customAxios, httpOptions} from "../../axiosConfiguration";
import {loginEndpoint, platformDetailsEndpoint, pollMFAEndpoint} from "../../urls";

export const getPlatformDetails = () => {
    return customAxios.get(platformDetailsEndpoint, httpOptions)
}

export const platformAuthLogin = (userName, password) => {
    return customAxios.post(loginEndpoint, {
        'userName': userName,
        'password': password,
        'nonce': 'some-random-value',
        'state': 'some-state'
    }, httpOptions)
}

export const platformAuthPollMFA = (pollUri) => {
    return customAxios.post(pollMFAEndpoint, {
        'pollUri': pollUri,
        'nonceState': {
            'nonce': 'some-random-value',
            'state': 'some-state'
        }
    }, httpOptions)
}