import { toast } from "react-toastify";

export const toastError = (errorMessage) => {
  return toast.error(errorMessage, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: true,
  });
};

export const toastSuccess = (message) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: true,
  });
};

export const toastWarning = (message) => {
  return toast.warn(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: true,
  });
};
