/**
 * withRouter is a Higher-order component with which we can pass location, navigate, params to our React components as props.
 * To use withRouter, Wrap your component inside withRouter() as a parameter.
 *      Ex: export default withRouter(App);
 */
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
};

export default withRouter;
