import React, {Component} from 'react';


class RequestAccessEmail extends Component {

    render() {

        const emailSubject = "Requesting access to platform dashboard";
        const emailBody = `Could you please grant access to the following platform dashboard environment: %0A%20%20%20%20<${this.props.link}>%0A%0AThank you.`;
        const adminMailTo = `mailto:${this.props.adminEmail}?subject=${emailSubject}&body=${emailBody}`;

        return (<a href={adminMailTo} className="btn btn-success" target="_blank" rel="noopener noreferrer">Request
            Now!</a>)
    }
}

export default RequestAccessEmail;