import { Role } from "./common/utils";
import {
    alertsPath, helpPath, homePath, listXApiKeyConfigsPath, resourcesPath, listStorageContainersPath,
    statusPath, securityMatrixPath, platformConfigsPath
} from "./urls";

export const navigation = (platformDetails, role = Role.USER) => {

    const getConfigurationNav = () => {
        const configurationNav =  {
            name: 'Configuration',
            icon: 'icon-wrench',
            children: [
                { name: 'X-Api-Key', url: listXApiKeyConfigsPath },
            ]
        };

        if (role === Role.ADMIN) {
            configurationNav.children.push({ name: 'Platform Config', url: platformConfigsPath });
        }

        return configurationNav;
    }

    return {
        items: [
            {
                title: true,
                name: `${platformDetails.version} ${platformDetails.envName}`,
                wrapper: {            // optional wrapper object
                    element: '',        // required valid HTML5 element tag
                    attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
                },
                class: 'bg-bcg-green'             // optional class names space delimited list for title item ex: "text-center"
            },
            {
                name: 'Home',
                url: homePath,
                icon: 'icon-home',
            },
            {
                name: 'Status',
                url: statusPath,
                icon: 'icon-info',
            },
            {
                name: 'Resources',
                url: resourcesPath,
                icon: 'icon-tag',
            },
            getConfigurationNav(),
            {
                name: 'Storage',
                icon: 'icon-social-dropbox',
                children: [
                    {
                        name: 'Containers',
                        url: listStorageContainersPath
                    }
                ]
            },
            {
                            name: 'Security Matrix(Beta)',
                            icon: 'icon-lock',
                            url: securityMatrixPath

             },
             {
                name: 'Alerts',
                url: alertsPath,
                icon: 'icon-bell',
            },
            {
                name: 'Help',
                url: helpPath,
                icon: 'icon-question'
            }]
    };
}
