import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { servicesToConfigureEndPoint } from "../urls";
import { getBaseURL } from "./config";

export const servicesToConfigureApi = createApi({
  reducerPath: "servicesToConfigureApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseURL(),
  }),
  endpoints: (builder) => ({
    getservicesToConfigure: builder.query({
      query: (xApiKey) => servicesToConfigureEndPoint(xApiKey),
    }),
  }),
});

export default servicesToConfigureApi;
