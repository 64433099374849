import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseURL } from "./config";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseURL(),
  }),
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => "/api/configuration/userinfo",
    }),
  }),
});

export default userApi;
